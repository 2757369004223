<template>
  <v-sheet class="mx-auto" elevation="0">
    <div v-if="items && items.length">
      <v-card
        v-for="(log, i) in items.slice().reverse()"
        :key="i"
        class="ma-4"
        min-height="200"
      >
        <v-card-title class="mb-3">{{ title(i, log) }}</v-card-title>
        <v-card-subtitle>{{ log.created_at.toLocaleString() }}</v-card-subtitle>
        <v-container class="py-3">
          <v-row>
            <v-container class="ma-3">
              <v-row v-if="Object.keys(log.diff.added).length">
                <div class="category">
                  {{ $t('tools.changelog.added') }}
                </div>
              </v-row>

              <v-row v-for="(v, i) in log.diff.added" :key="'added-' + i">
                <v-col cols="12" md="3"
                  ><span class="field">{{ i }}</span></v-col
                >
                <v-col cols="12" md="8">{{ val(v) }}</v-col>
              </v-row>

              <v-row v-if="Object.keys(log.diff.changed).length">
                <div class="category">
                  {{ $t('tools.changelog.changed') }}
                </div>
              </v-row>

              <v-row v-for="(ch, i) in log.diff.changed" :key="'changed-' + i">
                <v-col class="field" cols="12" md="3">{{ i }}</v-col>
                <v-col cols="12" md="4"
                  ><span class="old">{{ val(ch.old) }}</span></v-col
                >
                <v-col cols="12" md="1">
                  <v-icon>
                    mdi-arrow-right
                  </v-icon></v-col
                >
                <v-col cols="12" md="4">{{ val(ch.new) }}</v-col>
              </v-row>

              <v-row v-if="Object.keys(log.diff.deleted).length">
                <div class="category">
                  {{ $t('tools.changelog.deleted') }}
                </div>
              </v-row>

              <v-row v-for="(v, i) in log.diff.deleted" :key="'deleted-' + i">
                <v-col class="field" cols="12" md="3">{{ i }}</v-col>
                <v-col cols="12" md="8">{{ val(v) }}</v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else class="text-center elevation-2 pa-12 text-h5">
      No history available
    </div>
  </v-sheet>
</template>

<script>
import AuditLogService from '@/services/AuditLogService.js';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      items: null
    };
  },
  mounted() {
    this.load();
  },
  activated() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.item.id) {
        return;
      }

      this.loading = true;
      console.log('loading tool changelog');
      try {
        this.items = await AuditLogService.getItems('Tool', this.item.id);
        console.log('changelog:', this.items);
      } catch (error) {
        console.log('error:', error);
        this.showError(this, error);
      } finally {
        this.loading = false;
      }
    },
    title(i, log) {
      if (log.before) {
        return this.$t('tools.changelog.modified-by', [
          this.items.length - i - 1,
          log.user
        ]);
      } else {
        return this.$t('tools.changelog.created-by', [
          this.items.length - i - 1,
          log.user
        ]);
      }
    },
    val(v) {
      if (!v || v.length == 0) {
        return this.$t('tools.changelog.empty');
      }
      return v;
    }
  }
};
</script>

<style lang="scss" scoped>
.field {
  font-size: 1em;
  font-weight: bold;
}
.category {
  font-size: 1.2em;
  font-weight: bold;
}
.old {
  font-size: 1em;
  text-decoration: line-through;
}
.new {
  font-size: 1em;
}
</style>
