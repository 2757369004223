import request from './request';
import mixin from '../mixin';

export default {
  async getItems(entity_type, entity_id) {
    let url = `/api/auditlog/?entity_type=${entity_type}&entity_id=${entity_id}`;
    console.log('url:', url);
    let rsp = await request.request(url, 'get', {}, {});
    const logs = rsp.data.results;

    return logs.map(l => {
      l.created_at = mixin.methods.dateFromISO8601(l.created_at);
      return l;
    }, this);
  }
};
